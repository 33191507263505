import axios from "axios";
import Vue from "vue";

const planetAxios = axios.create({
  baseURL: "https://planet.api.eomap.com"
});

export const postPlanet = async data => {
  const response = await planetAxios.post("search", data, {
    headers: {
      Authorization: `Bearer ${Vue.prototype.$keycloak.token}`,
      "Content-Type": "application/json",
      accept: "application/json"
    }
  });
  return response.data;
};
