<template>
  <div class="d-flex flex-column full-height" v-if="!baseLoading">
    <div class="flex-grow-0">
      <order-stepper v-model="step" />
      <step-header
        :step="step"
        :title="'order.sceneSelectionStep.sceneSelection'"
        :sub-title="'order.sceneSelectionStep.selectScenesDescription'"
      ></step-header>
    </div>
    <v-container v-if="helper.resolutions.includes('coarse')">
      <v-row class="px-8">
        <v-col>
          <span style="color: red">{{
            $t("order.sceneSelectionStep.disclaimer")
          }}</span
          ><br />
          <span style="color: red">{{
            $t("order.sceneSelectionStep.descriptionS3Scenes")
          }}</span>
        </v-col>
      </v-row>
    </v-container>

    <v-row class="fill-height d-flex flex-column ">
      <v-col class="d-flex shrink pb-0">
        <scene-filter
          :order="order"
          :helper="helper"
          :allSelected="allScenesSelected"
          :filteredScenes="filteredScenes"
          :trigger-filter="showFilter"
          :search-scenes-loading="searchScenesLoading"
          @setFilteredDate="filterScenes"
          @applyFilter="applyFilter"
          @selectAll="selectAll"
          @removeAll="removeAll"
        ></scene-filter>
      </v-col>
      <v-col class="d-flex grow overflow-y-auto pt-0">
        <v-container class="pt-0 pl-11 pr-10">
          <v-row v-if="helper.historicRanges.length">
            <v-col>
              <v-card flat style=" background-color: transparent">
                <v-card-text style=" background-color: transparent ">
                  <v-row
                    v-for="(range, index) in helper.historicRanges"
                    :key="index"
                  >
                    <v-col cols="auto">
                      <span class="body-default" style="color: gray"
                        >{{ range.startDate }} to {{ range.endDate }}</span
                      ></v-col
                    >
                    <v-col style="align-self: center">
                      <v-divider v-if="!searchScenesLoading"></v-divider>
                      <v-progress-linear
                        v-else
                        indeterminate
                        :active="searchScenesLoading"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="scene in filteredScenes"
                      :key="scene.scene_name"
                    >
                      <v-card
                        min-width="230px"
                        max-width="240px"
                        @click="triggerScene(scene)"
                      >
                        <v-img height="248" contain :src="scene.thumbnail.href">
                          <template v-slot:placeholder>
                            <v-icon
                              class="no-image-icon"
                              color="grey lighten-1"
                              size="150"
                              >mdi-image-area</v-icon
                            >
                          </template>
                        </v-img>
                        <v-card-title>
                          <span class="font-weight-bold">{{
                            dateDisplay(scene.datetime)
                          }}</span>
                          <span style="font-size: 14px" class="pl-3">{{
                            timeDisplay(scene.datetime)
                          }}</span>
                        </v-card-title>
                        <v-card-subtitle class="subtitle-container pr-1">
                          <span>
                            <v-icon class="pr-1" small
                              >mdi-weather-cloudy</v-icon
                            >
                            <span class="pr-1" style="font-size: 11px"
                              >{{ scene.cloud_cover }} %</span
                            >
                            <v-icon class="pr-1" small
                              >mdi-satellite-variant</v-icon
                            >
                            <span class="pr-1" style="font-size: 11px">{{
                              sensorDict[scene.sensor]
                            }}</span>
                          </span>
                          <v-icon
                            v-if="sceneSelected(scene.scene_name)"
                            elevation="0"
                            color="secondary"
                            large
                            >mdi-check-circle</v-icon
                          >
                          <v-icon
                            v-else
                            class="check-icon"
                            elevation="0"
                            color="#A3B1C7"
                            large
                            >mdi-plus-circle</v-icon
                          >
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <empty-states
            v-if="!searchScenesLoading && !errorInSearch"
            :filtered-scenes="filteredScenes"
            :helper="helper"
            :possible-scenes="helper.possibleScenes[tab]"
            @searchScenes="searchScenes"
          ></empty-states>
          <error-scene-search v-if="errorInSearch"></error-scene-search>
        </v-container>
      </v-col>

      <v-col class="d-flex shrink">
        <v-row
          no-gutters
          class="pa-5"
          style="background-color: white"
          align="center"
        >
          <v-col cols="auto">
            <v-btn text @click="previousStep" :disabled="searchScenesLoading">
              <v-icon>mdi-chevron-left</v-icon>
              {{ $t("order.back") }}
            </v-btn>
          </v-col>
          <v-col class="d-flex flex-row-reverse" cols="auto">
            <v-btn color="secondary" text @click="cancel"
              >{{ $t("cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <span class="ml-4">
              {{ $t("order.sceneSelectionStep.selected") }}:
              {{ order.selectedScenes.length }}
            </span>
          </v-col>
          <v-spacer />
          <v-col cols="auto" class="-flex flex-row-reverse mr-16 ml-2">
            <v-row v-if="!loading">
              <h2 class="primary--text">
                <span class="font-weight-light">
                  {{
                    Math.ceil(
                      helper.scenePriceHistoric +
                        helper.scenePriceHistoric * 0.3
                    )
                  }}
                  Credits
                </span>
              </h2>
            </v-row>
            <v-row>
              <span
                class="font-weight-light pt-1"
                v-if="helper.scenePriceFuture"
              >
                +
                {{
                  Math.ceil(
                    helper.scenePriceFuture + helper.scenePriceFuture * 0.3
                  )
                }}
                Credits (Future scenes)
              </span>
            </v-row>
          </v-col>
          <v-col class="d-flex flex-row-reverse ml-4" cols="auto">
            <v-btn
              color="primary"
              rounded
              @click="nextStep"
              :disabled="
                (order.selectedScenes.length === 0 &&
                  !noSceneSelectionDialog) ||
                  clickSend
              "
              >{{ $t("continue") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <no-history-scenes-dialog
      v-if="noSceneSelectionDialog && !searchScenesLoading"
      @nextStep="nextStep"
      @previousStep="previousStep"
    ></no-history-scenes-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment/moment";
import creditMixin from "@/core/mixins/credit.mixin";
import StepHeader from "@/core/components/order/StepHeader.vue";
import SceneFilter from "@/core/components/order/steps/sceneSelection/SceneFilter.vue";
import OrderStepper from "@/core/components/order/OrderStepper.vue";
import NoHistoryScenesDialog from "@/core/components/order/steps/sceneSelection/NoHistoryScenesDialog.vue";
import priceCalculationMixin from "@/core/mixins/priceCalculation.mixin";
import EmptyStates from "@/core/components/order/steps/sceneSelection/EmptyStates.vue";
import { postPlanet } from "@/core/api/planet.api";
import Vue from "vue";
import ErrorSceneSearch from "@/core/components/order/steps/sceneSelection/errorSceneSearch.vue";

export default {
  name: "SceneSelection",
  mixins: [creditMixin, priceCalculationMixin],
  components: {
    ErrorSceneSearch,
    EmptyStates,
    NoHistoryScenesDialog,
    OrderStepper,
    SceneFilter,
    StepHeader
  },
  props: {
    value: Number,
    order: Object,
    helper: Object
  },
  data() {
    return {
      step: this.value,
      startDate: "",
      startMenu: false,
      endDate: "",
      endMenu: false,
      searchScenesLoading: false,
      sensorDict: {
        sn2: "Sentinel-2",
        ls8: "Landsat 8",
        ls9: "Landsat 9",
        sn3: "Sentinel-3",
        sdo: "Planet SuperDove"
      },
      clickSend: false,
      cloudCoverMin: 0,
      cloudCoverMax: 100,
      creditObject: [],
      possibleAccounts: [],
      colorArray: [],
      tab: 0,
      timerange: null,
      filteredScenes: [],
      noSceneSelectionDialog: false,
      allScenesSelected: false,
      loading: false,
      baseLoading: false,
      showFilter: true,
      errorInSearch: false
    };
  },
  methods: {
    defineRanges() {
      if (this.helper.timeranges.length) {
        this.splitFutureAndHistoricScenes(this.helper.timeranges);
      }
    },
    splitFutureAndHistoricScenes(ranges) {
      const today = moment().format("YYYY-MM-DD");
      ranges.forEach(range => {
        if (range.startDate >= today && range.endDate > today) {
          this.helper.futureRanges.push(range);
        } else if (range.startDate < today && range.endDate <= today) {
          this.helper.historicRanges.push(range);
        } else if (range.startDate < today && range.endDate > today) {
          this.helper.futureRanges.push({
            startDate: today,
            endDate: range.endDate
          });
          this.helper.historicRanges.push({
            startDate: range.startDate,
            endDate: today
          });
        }
      });
    },

    askAllScenesSelected() {
      if (this.filteredScenes.length) {
        this.allScenesSelected =
          this.helper.sceneCount[this.tab] >= this.filteredScenes.length;
      } else {
        this.allScenesSelected = false;
      }
    },
    nextStep() {
      this.$emit("updateOrder", this.order);
      this.$emit("updateHelper", this.helper);
      this.$emit("input", this.step + 1);
    },
    previousStep() {
      this.$emit("updateOrder", this.order);
      this.$emit("updateHelper", this.helper);
      this.$emit("input", this.step - 1);
    },
    cancel() {
      this.$router.push({ name: "Regions" });
    },
    filterScenes(object) {
      this.order.cloudCover = object.cloudCover;
      this.order.sensors = object.sensors;
    },
    applyFilter() {
      this.removeAll();
      this.filteredScenes = this.filterScenesBySensor();
      this.filteredScenes = this.filterScenesByCloudCover();
    },
    defineDateRanges() {
      let dateRanges = [];
      let tempEnd = this.timerange.endDate;
      let tempStart = moment(this.timerange.endDate)
        .subtract(30, "d")
        .format("YYYY-MM-DD");
      while (tempEnd >= this.timerange.startDate) {
        if (tempStart < this.timerange.startDate) {
          tempStart = this.timerange.startDate;
        }
        dateRanges.push({ tempStart, tempEnd });
        tempEnd = moment(tempStart)
          .subtract(1, "d")
          .format("YYYY-MM-DD");
        tempStart = moment(tempStart)
          .subtract(30, "d")
          .format("YYYY-MM-DD");
      }
      return dateRanges;
    },

    async fetchScenes(range) {
      const data = {
        start_date: range["tempStart"],
        end_date: range["tempEnd"],
        aoi: this.order.geojson,
        sensors: this.order.sensors
      };
      this.errorInSearch = false;

      try {
        const response = await axios.post("/orders/get-scenes/", data);
        response.data.forEach(scene => {
          this.helper.possibleScenes[this.tab].push(scene);
        });
        this.filteredScenes = this.helper.possibleScenes[this.tab];
        this.filteredScenes = this.filterScenesBySensor();
        this.filteredScenes = this.filterScenesByCloudCover();
      } catch {
        this.errorInSearch = true;
      }
    },

    async fetchPlanetScenes(range) {
      const data = {
        start_date: range["tempStart"],
        end_date: range["tempEnd"],
        geometry: this.order.geojson.features[0].geometry,
        sensors: this.order.sensors,
        max_cloud_cover: 100
      };

      const response = await postPlanet(data);
      const thumbnailPromises = response.map(async scene => {
        const thumbnailUrl = `https://planet.api.eomap.com/thumbnails/${scene.id}`;
        const thumbnailResponse = await axios.get(thumbnailUrl, {
          headers: {
            Authorization: `Bearer ${Vue.prototype.$keycloak.token}`
          },
          responseType: "arraybuffer"
        });
        const imageBase64 = Buffer.from(
          thumbnailResponse.data,
          "binary"
        ).toString("base64");
        scene.thumbnail = { href: `data:image/png;base64,${imageBase64}` };
        return scene;
      });
      const scenesWithThumbnails = await Promise.all(thumbnailPromises);
      this.helper.possibleScenes[this.tab] = this.helper.possibleScenes[
        this.tab
      ].concat(scenesWithThumbnails);
      this.filteredScenes = this.helper.possibleScenes[this.tab];
      this.filteredScenes = this.filterScenesBySensor();
      this.filteredScenes = this.filterScenesByCloudCover();
    },

    async searchScenes() {
      if (this.timerange?.startDate && this.timerange?.endDate) {
        this.helper.possibleScenes[this.tab] = [];
        this.filteredScenes = [];
        this.searchScenesLoading = true;
        this.showFilter = false;
        const dateRangeList = this.defineDateRanges();
        for (const range of dateRangeList) {
          if (this.order.sensors.includes("sdo")) {
            await this.fetchPlanetScenes(range);
          } else {
            await this.fetchScenes(range);
          }
        }
        this.searchScenesLoading = false;
      }
    },

    dateDisplay(datetime) {
      return datetime.replace("Z", "").split("T")[0];
    },
    timeDisplay(datetime) {
      return datetime.replace("Z", "").split("T")[1];
    },
    getMissionSceneDate(scene) {
      return scene.datetime.replace("Z", "").split(".")[0];
    },

    triggerScene(scene) {
      if (!this.sceneSelected(scene.scene_name)) {
        const sceneDate = this.getMissionSceneDate(scene);
        this.order.selectedScenes.push({
          scene_name: scene.scene_name,
          sensor: scene.sensor,
          scene_date: sceneDate,
          area_km2: scene?.area_km2,
          overlapping_aoi: scene?.overlapping_aoi
        });
        this.helper.sceneCount[this.tab] = this.helper.sceneCount[this.tab] + 1;
      } else {
        const removeIndex = this.order.selectedScenes
          .map(scene => scene.scene_name)
          .indexOf(scene.scene_name);
        this.order.selectedScenes.splice(removeIndex, 1);
        this.helper.sceneCount[this.tab] = this.helper.sceneCount[this.tab] - 1;
      }
    },

    sceneSelected(sceneId) {
      return this.order.selectedScenes.some(
        scene => scene.scene_name === sceneId
      );
    },

    selectAll() {
      this.helper.sceneCount[this.tab] = 0;
      this.filteredScenes.forEach(scene => {
        const alreadySelected = this.order.selectedScenes.filter(
          object => object.scene_name === scene.scene_name
        );

        if (!alreadySelected.length) {
          const sceneDate = this.getMissionSceneDate(scene);
          this.order.selectedScenes.push({
            scene_name: scene.scene_name,
            sensor: scene.sensor,
            scene_date: sceneDate,
            area_km2: scene?.area_km2,
            overlapping_aoi: scene?.overlapping_aoi
          });
        }
        this.helper.sceneCount[this.tab] = this.helper.sceneCount[this.tab] + 1;
      });
    },

    removeAll() {
      this.helper.sceneCount = new Array(this.helper.sceneCount.length).fill(0);
      this.order.selectedScenes = [];
    },

    filterScenesByCloudCover() {
      const tempFilteredScenes = this.filteredScenes.filter(
        scene =>
          scene.cloud_cover <= this.order.cloudCover ||
          scene.cloud_cover == null
      );
      return tempFilteredScenes;
    },

    filterScenesBySensor() {
      const tempFilteredScenes = this.helper.possibleScenes[
        this.tab
      ].filter(scene => this.order.sensors.includes(scene.sensor));
      return tempFilteredScenes;
    }
  },
  watch: {
    async "order.selectedScenes"() {
      this.loading = true;
      this.helper.scenePriceHistoric = await this.callPriceCalculation(
        this.order,
        this.order.timerange,
        this.order.selectedScenes.length
      );
      if (this.helper.futureRanges.length) {
        const numberFutureScenes = this.getSceneNumber(
          this.helper.futureRanges[0].startDate,
          this.helper.futureRanges[0].endDate
        );
        const count = this.order.selectedScenes.length + numberFutureScenes;
        const price = await this.callPriceCalculation(
          this.order,
          this.order.timerange,
          count
        );
        this.helper.scenePriceFuture = price - this.helper.scenePriceHistoric;
      }
      this.askAllScenesSelected();
      this.loading = false;
    }
  },
  async created() {
    this.baseLoading = true;
    this.helper.historicRanges = [];
    this.helper.futureRanges = [];
    this.defineRanges();
    this.timerange = this.helper.historicRanges[this.tab];
    if (this.helper.possibleScenes[this.tab].length) {
      this.filteredScenes = this.helper.possibleScenes[this.tab];
      this.filteredScenes = this.filterScenesBySensor();
      this.filteredScenes = this.filterScenesByCloudCover();
      this.askAllScenesSelected();
    } else {
      this.searchScenes();
    }
    if (this.helper.futureRanges.length) {
      const numberFutureScenes = this.getSceneNumber(
        this.helper.futureRanges[0].startDate,
        this.helper.futureRanges[0].endDate
      );
      const count = this.order.selectedScenes.length + numberFutureScenes;
      const price = await this.callPriceCalculation(
        this.order,
        this.order.timerange,
        count
      );
      this.helper.scenePriceFuture = price - this.helper.scenePriceHistoric;
    }
    this.creditObject = await this.fetchUserAccounts();
    this.requestPossibleAccounts(0).then(result => {
      this.possibleAccounts = result.account_ids;
    });
    if (this.helper.historicRanges.length == 0) {
      this.noSceneSelectionDialog = true;
    }
    this.baseLoading = false;
  }
};
</script>

<style scoped>
.subtitle-container {
  display: flex;
  align-items: center; /* Optional: Center the items vertically */
}

.subtitle-container span {
  flex-grow: 1; /* Allows the text to take up the available space */
}

.no-image-icon {
  display: flex;
  top: 50%;
  transform: translateY(-50%);
}
</style>
